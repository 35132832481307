<template>
  <div class="page-header">
    <h3>Manage Client</h3>
    <div>
      <button @click="showBookingModal = true">New Booking</button>
      <button class="secondary" @click="showClientModal = true">Edit Client</button>
      <button class="danger" @click="handleDelete">Delete Client</button>
    </div>
  </div>
  <div v-if="!isLoading && client" class="container">
    <div class="inner-container">
      <div class="page-subtitle">Client Details 🧑‍🦱</div>
      <ClientDetails :client="client" />
    </div>
    <div class="inner-container">
      <div class="page-subtitle">Bookings 🚗</div>
      <BookingTable :clientId="client.id" />
    </div>
    <div class="inner-container">
      <div class="page-subtitle">Invoices 🧾</div>
      <InvoiceTable :clientId="client.id" />
    </div>
  </div>
  <div v-else-if="isLoading" class="container">
    <div class="loading-documents">⏳ LOADING...</div>
  </div>
  <div v-else-if="error" class="container">
    <div class="loading-documents error">{{ error }}</div>
  </div>
  <div class="loading-page" :class="{ shown: fetchLoad }"><img src="@/assets/images/spinner.svg" alt="Loading..."></div>
  <ClientModal v-if="showClientModal" :mode="'edit'" :client="JSON.parse(JSON.stringify(client))" @modal-close="showClientModal = false" />
  <BookingModal v-if="showBookingModal" :clientProp="true" :client="{ id: client.id, name: client.name, tel: client.tel }" @modal-close="showBookingModal = false" />
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useFetch from '@/composables/useFetch'
import useFirestore from '@/composables/useFirestore'
import ClientDetails from '@/components/Client/Details.vue'
import ClientModal from '@/components/Client/Modal.vue'
import BookingModal from '@/components/Booking/Modal.vue'
import BookingTable from '@/components/Booking/Table.vue'
import InvoiceTable from '@/components/Invoice/Table.vue'
import { Swal, Toast } from '@/composables/useSwal'

export default {
  props: ['id'],
  components: { ClientDetails, BookingTable, InvoiceTable, ClientModal, BookingModal },
  setup(props) {
    const router = useRouter()
    const { document: client, error, isLoading, listenDocument } = useFirestore()
    const { error: fetchError, isLoading: fetchLoad, fetchData } = useFetch()
    const showClientModal = ref(false)
    const showBookingModal = ref(false)

    listenDocument('clients', props.id)

    const handleDelete = async () => {
      Swal.fire({
        title: 'Uh-oh...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Delete Client`,
        html: 
        `<h4>Deleting this Client is irreversible and you'll lose all of their data, including their Bookings & Invoices.</h4><br>
        <h4>Do you confirm?</h4></div>`
      }).then(async (result) => {
        if (result.isConfirmed) {
          await fetchData(`/clients/${props.id}`, 'delete')

          if (!fetchError.value) {
            Toast.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Client deleted successfully'
            })

            router.push({ name: 'Clients' })
          } else {
            Toast.fire({
              icon: 'error',
              title: 'Oops...',
              text: `Error: ${fetchError.value}`
            })
          }
        }
      })
    }

    return { client, error, isLoading, fetchLoad, showClientModal, showBookingModal, 
      handleDelete }
  }
}
</script>