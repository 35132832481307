<template>
  <table class="not-hoverable" style="margin-top: 10px;">
    <thead>
      <th>Name</th>
      <th>Phone</th>
      <th>Email</th>
      <th>Address</th>
    </thead>
    <tbody>
      <tr>
        <td :class="{ tilted: !client.name }">{{ client.name || 'Unknown' }}</td>
        <td :class="{ tilted: !client.tel }">{{ client.tel || 'Unknown' }}</td>
        <td :class="{ tilted: !client.email }">{{ client.email || 'Unknown' }}</td>
        <td :class="{ tilted: !client.address }">{{ client.address || 'Unknown' }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['client']
}
</script>